import utils from '../functions/functions'
export default {
    state: {
        fullScreenActive: false
    },
    actions: {
        FULLSCREEN_CHANGE: function(context, value){
            context.commit('FULLSCREEN_UPDATE', value)
        }
    },
    mutations: {
        FULLSCREEN_UPDATE: function(state, value){
            state.fullScreenActive = value
        }
    },
    getters: {
        FULLSCREEN_MODE: function(state){
            return state.fullScreenActive
        }
    }
}