<template>
    <div class="d-flex justify-content-between flex-wrap py-4">
        <NewsBlockItem v-for="(item, index) in items"
            v-bind:key="index"    
            v-bind:item="item"
        ></NewsBlockItem> 
    </div>
</template>
<script>
import NewsBlockItem from './NewsBlockItem'
export default {
    components: {
        NewsBlockItem
    },
    data(){
        return {
        }
    },
    props: ['items'],
}
</script>
