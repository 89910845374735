<template>
    <div>
        <b-link 
            :href="linkHref" 
            :to="linkTo" 
            :target="target"
            :class="linkClass" 
            @click="itemClick"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
            :style="linkStyle"
        >
            <b-icon :icon="iconVariant" class="mr-3"></b-icon>
            <!-- <aside class="d-flex flex-column flex-sm-row align-items-sm-center"> -->
                <span>
                    {{ item.title }}
                    <b-badge v-if="showBadge" pill variant="success" class="ml-3">Читать с рекламой</b-badge>
                </span>            
            <!-- </aside> -->
        </b-link>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { BLink, BBadge } from 'bootstrap-vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        BLink,
        BBadge
    },    
    data(){
        return {
            itemHover: false
        }
    },
    props: ['item', 'currentIndex', 'maxIndex', 'siteContent', 'fontWeight', 'borderColor', 'icon'],
    computed: {
        ...mapGetters(['PLATFORM']),
        linkClass: function(){
            const classBase = 'd-flex align-items-center py-2 text-decoration-none'
            const color = (this.itemHover) ? 'text-primary' : 'text-black'
            const border = (this.currentIndex != (this.maxIndex) - 1) ? 'border-bottom' : ''
            const borderColor = (this.borderColor) ? `border-${this.borderColor}` : ''
            // const fontWeight = (this.fontWeight) ? 'font-weight-bold' : ''
            return `${classBase} ${color} ${border} ${borderColor}` // ${fontWeight}
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        linkStyle: function(){
            const fontWeight = '500'
            const result = `font-weight:${fontWeight};`
            return (this.fontWeight) ? result : null
        },
        iconVariant: function(){
            return (this.icon) ? this.icon : 'newspaper'
        },
        showBadge: function(){
            const vueContext = this
            const isSmi2 = this.item.url.match(/smi2\./i)
            // return (utils.isRotator(vueContext) && isSmi2) ? true : false
            return false
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        },        
    },  
}
</script>

<style lang="sass" scoped>
a
    transition: all 0.2s
</style>
