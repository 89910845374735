<template>
    <div style="min-height: calc(100vh + 10px)" :class="wrapClass">
        <!-- testPage -->
        <FirebaseUnit v-if="testPage"></FirebaseUnit>
        <!-- <GetContent v-on:contentGetted="smi24Ready" v-bind:dataFeed="'smi24'"></GetContent> -->
        <!-- isProd -->
        <div v-if="isProd">
            <FlowLogic></FlowLogic>
        </div>

        <ScreenModal 
            v-bind:modalReady="fullscreenDialogTrigger" 
            v-bind:delayTime="0"
            v-bind:targetEvent="'fullscreen'"
        ></ScreenModal>

        <!-- Бегущая строка -->
        <TickerBlock 
            v-bind:goalName="'TickerBlock'"
            v-bind:dataFeed="dataFeed"
        ></TickerBlock>

        <!-- Верхний блок -->
        <section v-if="!STANDALONE">
            <b-container>
                <b-row>
                    <b-col>
                        <BlockJumbotron 
                            v-bind:dataFeed="dataFeed"
                            v-bind:goalName="'TopBlock'"
                        ></BlockJumbotron>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Контент-блок -->
        <section v-if="!STANDALONE" class="bg-light pb-4 pb-lg-5">
            <b-container>
                <b-row>
                    <b-col>
                        <PostContent v-bind:dataFeed="dataFeed"></PostContent>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Слайдер -->
        <section v-if="!STANDALONE">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="dataFeed"
                            class="mb-3"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>        

        <!-- Левый-правый кол -->
        <section v-if="!STANDALONE" class="bg-light py-3 py-sm-5">
            <b-container>
                <b-row>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                        ></SimpleBlock>
                    </b-col>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                        ></SimpleBlock>
                    </b-col>                    
                </b-row>
            </b-container>
        </section>

        <!-- Оверлей на темном фоне -->
        <section v-if="!STANDALONE" class="bg-dark">
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock1'"
                            v-bind:limit="6"
                            v-bind:dataFeed="dataFeed"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Текстовый -->
        <section v-if="!STANDALONE" class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlockFooter'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:limit="16"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="(this.STANDALONE) ? 'OverlayBlock' : 'OverlayBlockInfinity'"
                            v-bind:limit="0"
                            v-bind:dataFeed="dataFeed"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Тостер Временной интервал 15000 -->
        <!-- hasReferrer -->
        <ToastBlock
            v-if="hasReferrer"
            v-bind:limit="4"
            v-bind:goalName="'Toast'"
            v-bind:dataFeed="'smi24'"
            v-bind:timeInterval="15000"
        ></ToastBlock>        

        <!-- префетчим список постов -->
        <!-- <PostPrefetch v-if="needPrefetch && POSTS.length"
            v-bind:loadContent="!PREFETCHED_CONTENT"
            v-bind:loadMedium = "!PREFETCHED_MEDIUM"
            v-bind:loadMicro = "!PREFETCHED_MICRO"
        ></PostPrefetch> -->

    </div>
</template>


<script>
import utils from '../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import FlowLogic from '../components/flow/FlowLogic.vue'
import PostContent from '../components/content/PostContent'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import PostPrefetch from '../components/content/PostPrefetch'
import TickerBlock from '../components/integrations/TickerBlock'
import ToastBlock from '../components/integrations/ToastBlock.vue'
import BlockJumbotron from '../components/integrations/BlockJumbotron.vue'
import TextBlock from '../components/integrations/TextBlock.vue'
import OverlayBlock from '../components/integrations/OverlayBlock.vue'
import BlockRounded from '../components/integrations/BlockRounded.vue'
import ScreenModal from '@/components/flow/ScreenModal.vue'
import FirebaseUnit from '@/components/general/FirebaseUnit.vue'
export default {
    components: {
        FirebaseUnit,
        BContainer, 
        BRow, 
        BCol,        
        FlowLogic,
        PostContent,
        CarouselBlock,
        SimpleBlock,
        PostPrefetch,
        TickerBlock,
        ToastBlock,
        BlockJumbotron,
        TextBlock,
        OverlayBlock,
        BlockRounded,
        ScreenModal,
    },    
    data(){
        return{
            teasersCollection: [],
            postsListAPI: utils.restAPIlist(),
            needPrefetch: false,
            postId: utils.getIdFromAddressBar(),
            highRejection: ['395022','394989','395010','393791','395028','395348','394179','395348','395332',
                '395194', '395335', '389306', '395112', '395134', '395100', '395341', '395131', '394977', '395125', '395079'],
        }
    },
    computed: {
        ...mapGetters(['MIXED_READY', 'NEXT_POST_LIST', 'PLATFORM', 'STANDALONE', 'POSTS', 'FLOW_READY', 'INSTALLABILITY', 'BROWSER', 'IS_EMULATION']),
        hasReferrer: function(){
            return (utils.hasReferrer())
        },
        isProd: function(){
            return utils.isProd()
        },
        wrapClass: function(){
            const paddingDesktop = 'pt-5 mt-2'
            const paddingMobile = 'pt-5 mt-5 pt-sm-0 mt-sm-0'
            return (this.PLATFORM === 'desktop') ? paddingDesktop : paddingMobile
        },
        dataFeed: function(){
            return (utils.hasReferrer()) ? 'mixed' : 'site'
        },
        fullscreenDialogTrigger: function() {
            return (utils.fullScreenAbility() && !this.INSTALLABILITY && utils.hasReferrer()) ? true : false
        },
        isFraud: function(){
            const operaMatch = this.BROWSER.match(/Opera/i)
            return (operaMatch && utils.hasReffererSmi24()) ? true : false
        },
        isTrashBrowser: function(){
            const chromeMatch = this.BROWSER.match(/Chrome/i)
            const fireFoxMatch = this.BROWSER.match(/Firefox/i)
            const operaMatch = this.BROWSER.match(/Opera/i)
            const edgeMatch = this.BROWSER.match(/Edge/i)
            const yandexMatch = this.BROWSER.match(/Yandex/i)
            const safariMatch = this.BROWSER.match(/Safari/i)
            const notDesktop = (this.PLATFORM != 'desktop')
            const nonameMobile = (notDesktop && !chromeMatch && !fireFoxMatch && !operaMatch && !edgeMatch && !yandexMatch && !safariMatch)
            return (nonameMobile) ? true : false
            
        },
        highRejectionPage: function(){
            return (this.highRejection.includes(this.postId))
        },
        testPage: function(){
            return (this.postId === '384809') ? true : false
        }
    },
    methods: {
        ...mapActions(['FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_SMI24',
         'FETCH_INFOX', 'FETCH_LIST', 'SMI2_FETCH', 'FRAUD_DETECT', 'FETCH_SMI24_TEST']),
        smi24Ready: function(arr){
            if (this.isTrashBrowser || (this.highRejectionPage && this.PLATFORM != 'desktop' && !this.IS_EMULATION)){
                utils.consoleLog('make hard')
                const shuffled = utils.shuffleArray(arr)
                const randomItem = shuffled[0]
                const url = randomItem.url
                const vueContext = this
                utils.safeGoal('GoLink', vueContext)
                utils.safeGoal('HardMake', vueContext)
                document.location.href = url
            }
        }
    },
    created: function(){
        // console.log('this.postId', this.postId)
        if (utils.hasReferrer()){
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(3)
            const vueContext = this
            //ЗАКОММЕНТИТЬ||РАСКОММЕНТИТЬ
            this.FETCH_SMI24(vueContext)

            /*
            if (this.isFraud){
                this.FETCH_SMI24_TEST(vueContext)
            }else{
                this.FETCH_SMI24(vueContext)
            }
            */

            this.FETCH_INFOX(vueContext)   
            this.SMI2_FETCH(vueContext)
        }else{
            this.FETCH_LIST('main_full')
        }
        const vueContext = this
        const goalFullscreen = (utils.fullScreenAbility) ? 'fullscreenEnable' : 'fullscreenDisable'
        const goalInstallability = (this.INSTALLABILITY) ? 'installabilityTrue' : 'installabilityFalse'
        utils.safeGoal(goalFullscreen, vueContext)
        utils.safeGoal(goalInstallability, vueContext)
        if (this.fullscreenDialogTrigger){
            utils.safeGoal('fullscreenDialogTrigger', vueContext)
        }
        if (this.STANDALONE){
            utils.safeGoal('Standalone', vueContext)
        }
        if (this.BROWSER === 'Chrome' && this.PLATFORM != 'desktop'){
            utils.safeGoal('ChromeMobile', vueContext)
        }
        if (utils.smi24Ref()){
            utils.safeGoal('Ref24', vueContext)
        }
        if (utils.smi24UTM()){
            utils.safeGoal('UTM24', vueContext)
        }
        if (this.isFraud){
            this.FRAUD_DETECT(true)
        }
        
    },  
    mounted: function(){
        const vueContext = this
        if (this.isTrashBrowser){
            utils.safeGoal('cheburashka', vueContext)
        }
        if (this.highRejectionPage && this.PLATFORM != 'desktop' && !this.IS_EMULATION){
            utils.safeGoal('highRejection', vueContext)
        }
    },
    watch: {
        MIXED_READY: function(){
            if (!this.NEXT_POST_LIST.length){
                this.FETCH_LIST('exclusive_full')
            }
        },
        POSTS: function(){
            if (this.POSTS.length && !this.NEXT_POST_LIST.length){
                this.FETCH_LIST('exclusive_full')
            }
        },
        FLOW_READY: function(newVal, oldVal){
            if (newVal && !oldVal){
                const vueContext = this
                utils.safeGoal('FlowReady', vueContext)
            }
        },
    }
}
</script>

<style lang="sass">
$contentAnimationDuration: 2s
.app-content-preloader-wrap
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
//исчезновение прелоадера    
.disappear-leave
    opacity: 1
.disappear-leave-to
    opacity: 0    
.disappear-leave-active
    transition: opacity 0.3s
//появление контента
.appear-enter
    opacity: 0
.appear-enter-to
    opacity: 1    
.appear-enter-active
    transition: opacity 0.8s
</style>