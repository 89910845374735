<template>
    <!-- p-2 -->
    <router-link :to="item.url" class="app-news-link d-flex flex-column rounded border mb-3 text-decoration-none">
        <b-aspect :aspect="'16:9'" class="b-light position-relative mb-2">
            <div class="app-news-link__image-wrap d-flex justify-content-center align-items-center overflow-hidden rounded-top position-absolute">
                <b-img-lazy :src="item.img"></b-img-lazy>
            </div>
        </b-aspect>
        <div class="app-news-link__text-items px-2 pb-2">
            <h3 class="app-news-link__title">
                {{ item.title }}
            </h3>
        </div>
    </router-link>
</template>

<script>

// restAPIpost
import { BImgLazy, BAspect} from 'bootstrap-vue'
export default {
    components: {
        BImgLazy, 
        BAspect
    },
    props: ['item', 'siteContent'],
    methods: {

    }
}
</script>

<style lang="sass">
.app-news-link
    width: 100%
@media (min-width: 576px)    
    .app-news-link
        width: calc(50% - 8px)     
@media (min-width: 992px)          
    .app-news-link
        width: calc(33.3% - 8px)           
@media (min-width: 1200px)          
    .app-news-link
        width: calc(25% - 8px)  
@media (min-width: 1360px)                                    
    .app-news-link
        width: calc(25% - 16px)      

.app-news-link__image-wrap
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
.app-news-link__title
    font-size: 1rem
</style>
<style lang="sass" scoped>
img
    width: 100%
    height: auto
</style>