<template>
    <div id="unit_102978"></div>
</template>

<script>
export default {
    methods: {
        rtbRender: function(){
            
        }
    },
    created: function(){
        const sc = document.createElement('script')
        sc.type = 'text/javascript'
        sc.async = true
        sc.src = '//smi2.ru/data/js/102978.js' 
        document.head.appendChild(sc)
        // sc.charset = 'utf-8';
        // var s = document.getElementsByTagName('script')[0]; 
        // s.parentNode.insertBefore(sc, s);
    }
}
</script>