<template>
    <div></div>
</template>

<script>
import utils from '@/functions/functions'
import Bowser from "bowser"
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            devSession: false
        }
    },
    computed: {
        ...mapGetters([
            //fraudDetect
            'IF_FRAUD', 
            // devices
            'BROWSER', 'OS', 'PLATFORM', 'IS_EMULATION',
            //screen
            'SCREEN_SIZE', 'STANDALONE',
            //fullscreenState
            'FULLSCREEN_MODE',
            //flow
            'INSTALLABILITY'
        ]),
    },
    methods: {
        ...mapActions([
            //fraudDetect
            'FRAUD_DETECT', 
            //devices
            'SET_BROWSER', 'SET_OS', 'SET_PLATFORM', 'EMULATION_DETECT',
            //screen
            'SCREEN_CHECK', 'STANDALONE_CHECK',
            //fullscreenState
            'FULLSCREEN_CHANGE',
            //flow
            'INSTALLABILITY_CHECK'
        ]),
        deviceCheck: function(){
            // console.log('deviceCheck')
            const vueContext = this
            const deviceItems = Bowser.parse(window.navigator.userAgent)
            const browserName = deviceItems.browser.name
            const osName = deviceItems.os.name
            const platformType = deviceItems.platform.type
            //сначала ставим в потоке
            this.SET_BROWSER(browserName)
            this.SET_OS(osName)
            this.SET_PLATFORM(platformType)
            //потом дублируем для надежности таймаутом
            window.setTimeout(function(){
                const deviceItems = Bowser.parse(window.navigator.userAgent)
                const browserName = deviceItems.browser.name
                const osName = deviceItems.os.name
                const platformType = deviceItems.platform.type                
                vueContext.SET_BROWSER(browserName)
                vueContext.SET_OS(osName)
                vueContext.SET_PLATFORM(platformType)
            }, 100)
        },
        devSessionCheck: function(){
            const devSession = localStorage.getItem('devSession')
            const masterDev = localStorage.getItem('masterDev')
            if (devSession && !masterDev){
                this.devSessionStateUpdate()
            }
        },
        devSessionStateUpdate: function(){
            this.EMULATION_DETECT(true)
            const vueContext = this
            utils.safeGoal('devSession', vueContext)
        }
    },
    created: function(){
        const vueContext = this
        this.deviceCheck()
        this.SCREEN_CHECK()
        this.INSTALLABILITY_CHECK()
        this.STANDALONE_CHECK()
        this.devSessionCheck()
        window.addEventListener('resize', function(){
            vueContext.SCREEN_CHECK()
            //чтобы работало на эмуляции при тесте
            vueContext.deviceCheck()
            vueContext.STANDALONE_CHECK()
        })
        //мониторим фуллскрины
        document.addEventListener("fullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("mozfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("webkitfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("msfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        //активность вкладки
        document.addEventListener('visibilitychange', function(){
            // console.log(`document.hidden is ${document.hidden}`)
        })        
    },
    watch: {
        FULLSCREEN_MODE: function(){
            if (this.FULLSCREEN_MODE){
                const vueContext = this
                utils.safeGoal('fullscreen', vueContext)
            }
        },
        BROWSER: function(newVal, oldVal){
            this.devSession = (!!oldVal && (newVal != oldVal)) ? true : this.devSession
        },
        OS: function(newVal, oldVal){
            this.devSession = (!!oldVal && (newVal != oldVal)) ? true : this.devSession
        },
        PLATFORM: function(newVal, oldVal){
            this.devSession = (!!oldVal && (newVal != oldVal)) ? true : this.devSession
        },  
        devSession: function(){
            this.EMULATION_DETECT(this.devSession)
        },
        IS_EMULATION: function(){
            localStorage.setItem('devSession', '1')
            this.devSessionStateUpdate()
        } 
    }
}
</script>