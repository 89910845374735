<template>
    <div class="d-flex align-items-start mb-3 w-100">
        <div class="flex-shrink-0 mr-3 mt-1 position-relative rounded overflow-hidden" style="width: 100px;">
            <b-aspect :aspect="aspect"></b-aspect>
            <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0;">
                <b-skeleton height="100%" width="100%"></b-skeleton>
            </div>
        </div>

        <div class="d-flex flex-column w-100">
            <b-skeleton width="100%" class="mt-1 mb-2"></b-skeleton>
            <b-skeleton width="50%" class="mt-1 mb-2"></b-skeleton>
        </div>
    </div>
</template>

<script>
import {BAspect, BSkeleton} from 'bootstrap-vue'
export default {
    components: {
        BAspect, 
        BSkeleton
    },
    props: ['aspect'],
    mounted: function(){

    }
}
</script>
