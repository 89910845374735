export default {
    state: {
        browser: '',
        os: '',
        platform: '',
        isEmulation: false
    },
    actions: {
        SET_BROWSER(context, browser){
            context.commit('UPDATE_BROWSER', browser)
        },
        SET_OS(context, os){
            context.commit('UPDATE_OS', os)
        },
        SET_PLATFORM(context, platform){
            context.commit('UPDATE_PLATFORM', platform)
        },  
        EMULATION_DETECT(context, value){
            context.commit('EMULATION_STATE_UPDATE', value)
        }      
    },
    mutations: {
        UPDATE_BROWSER(state, browser){
            state.browser = browser
        },
        UPDATE_OS(state, os){
            state.os = os
        },
        UPDATE_PLATFORM(state, platform){
            state.platform = platform
        },
        EMULATION_STATE_UPDATE(state, value){
            state.isEmulation = value
        }
    },
    getters: {
        BROWSER(state){
            return state.browser
        },
        OS(state){
            return state.os
        },
        PLATFORM(state){
            return state.platform
        },
        IS_EMULATION(state){
            return state.isEmulation
        }
    }
}