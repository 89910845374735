<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <!-- Контентные айтемы -->
        <b-alert v-if="contentReady" variant="secondary" :class="wrapClass" show>
            <BlockRoundedItem v-for="(item, index) in blockItems"
                v-bind:key="index"
                v-bind:item="item"
                v-bind:index="index"
                v-bind:siteContent="siteContent"
                v-bind:goalName="goalName"
            ></BlockRoundedItem>
        </b-alert>
        <!-- Прелоадерские айтемы -->
        <b-alert v-else variant="secondary" :class="wrapClass" show>
            <BlockRoundedPreloader v-for="(item, index) in blockPreloader"
                v-bind:key="index"
            ></BlockRoundedPreloader>
        </b-alert>
    </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import utils from '../../functions/functions'
import { mapGetters } from 'vuex'
import BlockRoundedItem from './BlockRoundedItem.vue'
import BlockRoundedPreloader from './BlockRoundedPreloader.vue'
export default {
    components: {
        BAlert,
        BlockRoundedItem,
        BlockRoundedPreloader
    },
    data(){
        return {
            blockItems: [],
            blockPreloader: ['', ''], 
            wrapClass: 'd-flex flex-column flex-md-row justify-content-between mb-0 p-2 px-md-3 py-md-2 px-lg-4 py-lg-3 alert-secondary'
        }
    },
    props: ['limit', 'dataFeed', 'goalName'],
    computed: {
        ...mapGetters(['REFRESHING']),
        contentReady: function(){
            return (this.blockItems.length && !this.REFRESHING) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        }
    },
    methods: {
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            const limit = (this.limit) ? this.limit : arr.length
            this.blockItems = shuffled.slice(0, limit)            
        }
    },
    watch: {
        REFRESHING: function(){
            if (this.REFRESHING){
                this.blockItems = []
            }
        }
    }
}

</script>