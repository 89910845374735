<template>
    <div>
        <section>
            <ScreenModal
                v-bind:modalReady="modalReady" 
                v-bind:delayTime="delayTime"
                v-bind:targetEvent="'pwa'"
            ></ScreenModal>
            <ScreenOverlay v-if="desktopOverlay"></ScreenOverlay>            
        </section>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import ScreenModal from './ScreenModal'
import ScreenOverlay from './ScreenOverlay'

export default {
    data(){
        return {
            //маркер того, что инсталлер был показан
            beforeInstall: false,
            //маркер общей готовности
            flowReady: false,
            desktopOverlay: false            
        }
    },
    components: {
        ScreenModal,
        ScreenOverlay,
    },
    computed: {
        ...mapGetters(['START_TIME', 'OS']),
        modalReady: function(){
            return (this.beforeInstall && !window.matchMedia('(display-mode: standalone)').matches) ? true : false
        },
        delayTime: function(){
            // delayTime для эмуляции нового визита (только для демонстрации на локале - 5000)
            return (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK) ? 0 : 0
        }
    },
    created: function(){
        const vueContext = this
        window.addEventListener('focus', function(){
            vueContext.desktopUnBlur()
        })
    },
    beforeMount: function(){
        /*  основнаая логика    */
        let vueContext = this
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
            //превентим дефолтное поведение
            e.preventDefault()
            deferredPrompt = e
            //ставим флаг для вотчинга
            this.flowReady = true
            // показываем модалку и сразу вешаем на неё обработчик
            this.beforeInstall = (!this.beforeInstall) ? true : this.beforeInstall
            //сабмитим цель
            // utils.safeGoal('FlowReady', vueContext) //сабмитим во вьюхе
            //пишем время
            // utils.timeSend(this.START_TIME)
            //навешиваем обработчик на модалку и кнопку
            const triggerElements = document.getElementsByClassName('app-install-trigger')
            for (let i=0; i<triggerElements.length; i++){
                //let installTrigger = document.getElementById('app-modal')            
                const installTrigger = triggerElements[i]
                installTrigger.addEventListener('click', (e) => {
                    //превентим дефолт
                    e.preventDefault()
                    //оборачиваем основную логику в try catch
                    try {
                        //показываем десктопное затемнение
                        vueContext.desktopBlur()
                        //показываем Инсталлер
                        deferredPrompt.prompt();
                        //сабмитим цель
                        utils.safeGoal('PromptShow', vueContext)
                        //ждем результат                
                        deferredPrompt.userChoice.then((choiceResult) => {
                            //убираем десктопное затемнение
                            vueContext.desktopUnBlur()                        
                            if (choiceResult.outcome === 'accepted') {
                                //фиксируем во vuex
                                vueContext.SUBSCRIBE_SET()
                                //убираем фуллскрин
                                //только если не встандалон-моде, иначе ломается
                                if (!window.matchMedia('(display-mode: standalone)').matches){
                                    try {
                                        // document.exitFullscreen()
                                    }catch(e){
                                        //Логирование ошибок реализуется на нашей стороне
                                        utils.safeGoal('ErrorFullscreenIn', vueContext)
                                    }                                    
                                }                                                                                        
                                //сабмитим цель 
                                utils.safeGoal('PromptAccept', vueContext)
                            }else{
                                // если НЕ подписка
                                //сабмитим цель 
                                utils.safeGoal('PromptDecline', vueContext)
                            }
                            //грохаем deferredPrompt
                            deferredPrompt = null;
                        });
                    } catch(e){
                        //Логирование ошибок реализуем на своей стороне
                        utils.safeGoal('ErrorInLogic', vueContext)
                    }
                })
            }
        })        
    },
    methods: {
        ...mapActions(['FLOW_READY_CHECK', 'SUBSCRIBE_SET']),
        desktopBlur: function(){
            if (this.OS === 'Windows'){
                this.desktopOverlay = true
                const htmlParentEl = document.getElementsByTagName('html')[0]
                htmlParentEl.style.filter = 'blur(1px)'
            }
        },
        desktopUnBlur: function(){
            this.desktopOverlay = false
            const htmlParentEl = document.getElementsByTagName('html')[0]
            htmlParentEl.removeAttribute('style')
        }        
    },
    watch: {
        flowReady: function(newVal, oldVal){
            const ready = (newVal && !oldVal)
            this.FLOW_READY_CHECK(ready)
        },
    }
}
</script>