<template>
    <b-link 
        :class="classLink" 
        :to="linkTo" 
        :href="linkHref" 
        :target="target" 
        @click="itemClick"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
    >
        <b-aspect :aspect="aspect" class="d-flex justify-content-center align-items-start flex-shrink-0 mr-3 mt-1 position-relative" style="width: 100px;">
            <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0">
                <b-skeleton width="100%" height="100%"></b-skeleton>
            </div>
            <figure class="rounded overflow-hidden d-flex justify-content-center align-items-center position-absolute">
                <b-img-lazy :src="item.img" :style="imgStyle"></b-img-lazy> 
            </figure>
        </b-aspect>
        <span class="font-weight-bold">
            {{ item.title }}
        </span>
    </b-link>
</template>

<script>
import { mapGetters } from 'vuex'
import { BImgLazy, BAspect, BLink, BSkeleton } from 'bootstrap-vue'
export default {
    components: {
        BImgLazy,
        BAspect,
        BLink, 
        BSkeleton
    },
    data(){
        return {
            itemHover: false
        }
    },
    props: ['item', 'aspect', 'siteContent'],
    computed: {
        ...mapGetters(['PLATFORM']),
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : this.item.url
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        imgStyle: function(){
            let style = null
           return (this.siteContent) ? null : 'height:100%; width:auto;'
        },  
        classLink: function(){
            const classBase = 'd-flex align-items-start mb-3 text-decoration-none'
            const textColor = (this.itemHover) ? 'text-primary' : 'text-black'
            return `${classBase} ${textColor}`
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        },
    },
}
</script>

<style lang="sass" scoped>
span
    font-size: 1rem 
    line-height: 1.4   
    transition: all 0.2s
figure
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0
</style>