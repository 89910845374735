import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PostView from '../views/PostView.vue'
import RotatorView from '../views/RotatorView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/post',
    name: 'post',
    component: PostView
  },
  {
    path: '/app',
    name: 'app',
    component: RotatorView
  },
  {
    path: '/pwa',
    name: 'pwa',
    component: RotatorView
  },       
  {
    path: '/pwa1',
    name: 'pwa1',
    component: RotatorView
  },
  {
    path: '/pwa2',
    name: 'pwa2',
    component: RotatorView
  },    
  {
    path: '/pwa3',
    name: 'pwa3',
    component: RotatorView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 }
  }
})

export default router
