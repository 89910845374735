<template>
  <div :class="wrapClass">
    <!-- слайдер -->
    <section v-if="!hasReferrer" class="mb-4 mt-0">
      <b-container>
        <b-row>
          <b-col>
            <CarouselBlock
              v-bind:limit="12"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedCarousel"
            ></CarouselBlock>    
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- половинчатый блок -->
    <section v-if="!hasReferrer" class="bg-light pb-2">
      <b-container class="pt-3 pt-md-4">
        <b-row class="py-lg-4">
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:infinity="false"
              v-bind:autoCount="false"
              v-bind:limit="3"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedSimple"
          ></SimpleBlock>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:infinity="false"
              v-bind:autoCount="false"              
              v-bind:limit="3"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedSimple"
            ></SimpleBlock>
          </b-col>        
        </b-row>
      </b-container>
    </section>

    <!-- Текстовый под реф -->
    <section v-if="hasReferrer" class="pt-3">
        <b-container>
            <b-row>
                <b-col>
                    <TextBlock
                        v-bind:goalName="'ClickFromHome'"
                        v-bind:dataFeed="'mixed'"
                        v-bind:limit="60"
                        ></TextBlock>            
                </b-col>
            </b-row>
        </b-container>
    </section>

    <!-- блок с оверлеями -->
    <section class="pt-2">
      <b-container>
        <b-row>
          <b-col>
            <OverlayBlock 
              v-bind:dataFeed="feedOverlay"
              v-bind:limit="limit"
              v-bind:goalName="'ClickFromHome'"
            ></OverlayBlock>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Тостер -->
    <ToastBlock
      v-if="hasReferrer"
      v-bind:limit="4"
      v-bind:goalName="'Toast'"
      v-bind:timeInterval="15000"
    ></ToastBlock>

    <!-- префетчинг постов -->
    <!-- needPrefetch &&  -->
    <!-- <PostPrefetch v-if="needPrefetch && POSTS.length"
      v-bind:loadContent="!this.PREFETCHED_CONTENT"
      v-bind:loadFull="!this.PREFETCHED_FULL"
    ></PostPrefetch> -->

  </div>
</template>

<script>
import utils from '../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import NewsBlock from '../components/content/NewsBlock'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import PostPrefetch from '../components/content/PostPrefetch'
import ToastBlock from '../components/integrations/ToastBlock.vue'
import TextBlock from '@/components/integrations/TextBlock.vue'

export default {
  components: {
        BContainer, 
        BRow, 
        BCol,
        CarouselBlock,
        NewsBlock,
        SimpleBlock,
        OverlayBlock,
        PostPrefetch,
        ToastBlock,
        TextBlock
    },   
  data(){
    return {
      needPrefetch: false
    }
  },
  computed: {
    ...mapGetters(['POSTS', 'PREFETCHED_CONTENT', 'PREFETCHED_FULL', 'LAST_ROUTE', 'POSTS', 'PLATFORM']),
    feedCarousel: function(){
      return (!utils.hasReferrer()) ? 'site' : 'mixed'
    },
    feedSimple: function(){
      return (!utils.hasReferrer()) ? 'site' : 'mixed'
    },
    feedOverlay: function(){
      return (!utils.hasReferrer()) ? 'site' : 'mixed'
    },
    goalName: function(){
      return (!utils.hasReferrer()) ? null : 'ClickFromHome'
    },
    limit: function(){
      return (utils.hasReferrer()) ? 0 : 30
    },
    isDirectHit: function(){
      return !utils.hasReferrer()
    },
    hasReferrer: function(){
      return utils.hasReferrer()
    },
    wrapClass: function(){
        const paddingDesktop = 'pt-5 mt-2'
        const paddingMobile = 'pt-5 mt-5 pt-sm-0 mt-sm-0'
        return (this.PLATFORM === 'desktop') ? paddingDesktop : paddingMobile
    }    
  },
  methods: {
    ...mapActions(['FETCH_LIST', 'TRACK_ROUTE', 'FETCH_SMI24', 'FETCH_INFOX', 'RESET_ALL', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE']),
  },
  created: function(){
    // this.RESET_ALL()
    // this.FETCH_LIST('main_full')
  },
  beforeMount(){
    // this.RESET_ALL()
  },
  mounted: function(){
    if (utils.hasReferrer() ){ //|| !utils.isProd()
      const vueContext = this
      // this.FETCH_SMI24(vueContext)
        this.FEED_COUNT_RESET()
        this.FEED_SUM_DEFINE(3)
        //ЗАКОММЕНТИТЬ||РАСКОММЕНТИТЬ
        this.FETCH_SMI24(vueContext)
        this.FETCH_INFOX(vueContext)   
        this.SMI2_FETCH(vueContext)
    }else{
      //чекаем маршшрут
      //здесь фетчим в любом случае, варьируется только режим - из кэша или из интернетов
      // const cacheMode = (this.LAST_ROUTE) ? 'force-cache' : 'default'
      // this.FETCH_LIST(cacheMode)    
      // this.needPrefetch = true

      this.FETCH_LIST('main_full')
    }

  },
  beforeDestroy: function(){
    //трекаем маршрут
    this.TRACK_ROUTE(this.$route.name)
  },
  watch: {
  }
}
</script>
