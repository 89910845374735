<template>
    <div class="pt-5 mt-5 mt-sm-0">
        <TechnicalProps></TechnicalProps>
        <!-- слайдер -->
        <section class="bg-light d-flex justify-content-center align-items-center pt-2">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- текстовый блок -->
        <section class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'

export default {
    data(){
        return {
        }
    },
    components: {
        BContainer, 
        BRow, 
        BCol,
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
    },
    computed: {

    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE']),
    },
    created: function(){
        this.FEED_COUNT_RESET()
        this.FEED_SUM_DEFINE(2)
        const vueContext = this
        this.FETCH_SMI24(vueContext)
        this.SMI2_FETCH(vueContext)
    },
    watch: {

    }
}
</script>

<style lang="sass">

</style>