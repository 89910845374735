<template>
    <span class="d-flex align-items-center">
        <div class="position-relative rounded-circle overflow-hidden d-flex justify-content-center align-items-center mr-3 flex-shrink-0" style="width: 100px; height: 100px;">
            <figure class="position-absolute">
                <b-skeleton height="100%" width="100%"></b-skeleton>
            </figure>
        </div>
        <div class="w-100 d-flex flex-column">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
        </div>
    </span>
</template>


<script>
import { BSkeleton } from 'bootstrap-vue'
export default {
    components: {
        BSkeleton
    }
}
</script>

<style lang="sass" scoped>
figure
    margin: 0
    top: 0
    left: 0
    right: 0
    bottom: 0
span    
    width: 100%
@media (min-width: 768px)    
    span
        width: calc(50% - 16px)
</style>