<template>
    <div class="app-post">
        <!-- Заг -->
        <h1 v-if="POST.title" class="my-3 my-lg-4 font-weight-bold text-black">
            {{ POST.title }}
        </h1>
        <!-- Прелоадер зага -->
        <div v-else class="d-flex flex-column my-3 my-lg-4">
            <b-skeleton height="24px"></b-skeleton>
            <b-skeleton height="24px" width="80%" class="d-lg-none"></b-skeleton>
            <b-skeleton height="24px" width="60%" class="d-sm-none"></b-skeleton>
        </div>

        <!-- НОВАЯ СТРУКТУРА НАЧАЛО -->
        <b-container>
            <b-row>
                <b-col md="6" class="px-0">
                    <!-- Картинка -->
                    <div class="d-flex align-items-center h-100 w-100 bg-secondary">
                        <b-aspect 
                            :aspect="'16:9'"
                            class="flex-shrink-0 d-flex justify-content-center align-items-start position-relative rounded overflow-hidden w-100"
                            >
                            <div>
                                <!-- хз почему, но выперает снизу, поэтому bottom 2px -->
                                <div class="position-absolute" style="top: 0; bottom: 2px; left: 0; right: 0">
                                    <b-skeleton height="100%" class="my-0"></b-skeleton>
                                </div>
                                <div @click="imageClick">
                                    <b-img-lazy :src="POST.img" class="position-relative rounded"></b-img-lazy>
                                </div>
                            </div>
                        </b-aspect>
                    </div>
                </b-col>
                <b-col md="6" class="px-0">
                    <div class="d-flex align-items-center justify-content-center bg-secondary h-100">
                        <Smi2RTB></Smi2RTB>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- НОВАЯ СТРУКТУРА КОНЕЦ -->


        <div class="d-flex flex-column flex-lg-row align-items-lg-start">

            <!-- Видимая часть текста -->
            <div class="app-post__head-items d-flex flex-column justify-content-between mt-3 w-100 px-0">
                <div v-if="POST.text.length" class="text-dark">
                    <p>
                        {{ POST.text[0] }}
                    </p>
                    <p v-if="SCREEN_SIZE >= mediaBreakpoint">
                        {{ POST.text[1] }}
                    </p>
                </div>
                <div v-else class="d-flex flex-column">
                    <b-skeleton class="mb-4"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                    <b-skeleton class="d-none d-lg-block mb-2"></b-skeleton>
                </div>

                <div v-if="POST.text.length && textHidden">
                    <b-button
                        variant="primary" 
                        @click="showText()"
                        size="lg"
                        block
                    >
                        <span>Читать полностью</span>
                        <b-icon icon="three-dots" class="ml-2"></b-icon>
                    </b-button>
                </div>
            </div>
        </div>
        <transition name="fade">
            <!-- textHidden -->
            <div v-show="!textHidden" class="mt-lg-3">
                <TextPart v-bind:items="textUnderCut" v-bind:dataFeed="dataFeed"></TextPart>
            </div>
        </transition>
    </div>
</template>

<script>
//ВКРУТИТЬ ОБРАБОТКУ ССЫЛОК В ТЕКСТЕ. ПРИМЕР ЗДЕСЬ 365623
import {  mapActions, mapGetters } from 'vuex'
import utils from '../../functions/functions'
import TextBlock from '../integrations/TextBlock.vue'
import { BButton, BAspect, BImgLazy, BSkeleton, BAlert, BContainer, BRow, BCol } from 'bootstrap-vue'
import Smi2RTB from '../integrations/Smi2RTB.vue'
import TextPart from './TextPart.vue'
export default {
    components: {
        BButton,
        BAspect,
        BImgLazy, 
        BSkeleton,
        TextBlock, 
        BAlert,
        Smi2RTB, 
        BContainer, 
        BRow, 
        BCol,
        TextPart
    },
    data(){
        return {
            textArray: [],
            textHide: true,
            mediaBreakpoint: 4,
            textBreak: 3,
            postId: utils.getIdFromAddressBar(),
        }
    },
    props: ['dataFeed'],
    computed: {
        ...mapGetters(['POST', 'SCREEN_SIZE', 'DEVICE', 'FULLTEXT', 'POST_LIST_BY_ID']),
        textUnderCut: function(){
            const trimStart = (this.SCREEN_SIZE >= this.mediaBreakpoint) ? 2 : 1
            const max = this.POST.text.length
            return this.POST.text.slice(trimStart, max)
        },
        textHidden: function(){
            //return !this.textHide
            return (!this.FULLTEXT && utils.fullScreenAbility())
        },
        isDirectHit: function(){
            return !utils.hasReferrer()
        },
        isSMI2Hit: function(){
            return utils.hasReffererSMI2()
        },
        /*
        postId: function(){
            return utils.getIdFromAddressBar()
        }
        */
    },    
    methods: {
        ...mapActions(['FETCH_POST', 'RESET_POST', 'TEXT_STATE_SET', 'SET_POST']),
        showText:function(){
            this.TEXT_STATE_SET(true)
            //ReadMore
            const vueContext = this
            utils.safeGoal('ReadMore', vueContext)
            utils.setFullscreen()
        },
        imageClick: function(){
            const vueContext = this
            utils.safeGoal('ImageClick', vueContext)
            utils.setFullscreen()
        }
    },   
    created: function(){
        //фетчим данные
        const id = utils.getIdFromAddressBar()
        //Наверное надо как-то сбросить
        this.FETCH_POST(id)
    },
    beforeDestroy: function(){
        // this.RESET_POST()
    },
    watch: {
        $route: function(){
            this.postId = (this.$route.query.p) ? this.$route.query.p : this.postId
        },
        postId: function(){
            const newPost = this.POST_LIST_BY_ID[`${this.postId}`]
            if (newPost){
                this.SET_POST(newPost)
            }else{
                this.FETCH_POST(this.postId)
            }
            
        }
    },
}
</script>


<style lang="sass" scoped>
img
    width: 100%
    height: auto
    cursor: zoom-in
h1
    font-weight: 500
    font-size: 1.3rem   
section
    // min-height: 410px     
</style>

<style lang="sass">
@keyframes content-append 
    0%
        opacity: 0
    100%
        opacity: 1

</style>


