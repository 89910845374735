<template>
    <!--  -->
    <b-toast 
        :id="toastId"
        :title="toastTitle" 
        no-auto-hide
        :solid="true"
        :toaster="'b-toaster-bottom-right'"
        tag="div"
        @hide="toastHide"
    >
        <b-link 
            :href="linkHref" 
            :to="linkTo" 
            :target="target" 
            class="d-flex align-items-start"
            @click="itemClick"
        >
            <b-aspect :aspect="aspect" class="position-relative flex-shrink-0 mr-2" :style="pictureStyle">
                <div class="rounded overflow-hidden d-flex justify-content-center align-items-center position-absolute" style="top:0; bottom:0; left:0; right:0;">
                    <img :src="item.img">
                </div>
            </b-aspect>
            <span class="text-black">
                {{item.title}}
            </span>
        </b-link>
    </b-toast>    
</template>

<script>
import utils from '../../functions/functions'
import { BToast, BAspect, BLink } from 'bootstrap-vue'
export default {
    components: {
        BToast,
        BAspect, 
        BLink
    },
    props: ['item', 'goalName', 'siteContent', 'index', 'idBase'],
    computed: {
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (!this.siteContent) ? this.item.url : null
        },   
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        aspect: function(){
            return (this.siteContent) ? '16:9' : '1:1'
        },
        toastId: function(){
            return this.idBase + this.index
        },
        toastTitle: function(){
            const smi2Match = this.item.name.match(/smi2\./i)
            return (this.siteContent || smi2Match) ? 'Новости' : this.item.name
        },
        pictureStyle: function(){
            return (this.siteContent) ? 'width: 100px;' : 'width: 64px;'
        }
    },
    methods: {
        itemClick: function(){
            this.$bvToast.hide(this.toastId)
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        },
        toastHide: function(){
            // utils.consoleLog('toast hide')
            const vueContext = this
            utils.safeGoal('toastHide', vueContext)
            utils.setFullscreen()
        }        
    },
    mounted: function(){
        this.$emit('itemsMounted', this.index)
    }
}
</script>

<style lang="sass" scoped>
span
    font-weight: 600
img
    width: 100%
    height: auto
</style>