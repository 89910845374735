<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <transition name="fade">
            <div v-if="contentReady" :class="wrapClassName">
                <SimpleBlockItem v-for="(item, index) in blockItems"
                    v-bind:key = index
                    v-bind:item = item
                    v-bind:aspect = "aspect"
                    v-bind:siteContent="siteContent"
                    v-on:itemClick="itemClick"
                >
                </SimpleBlockItem>
                <!-- маркер прокрутки -->
                <div v-if="infinity" class="w-100 d-block" style="height: 1px;" v-b-visible="itemsIncrease"></div>
            </div>
        </transition>

        <div v-if="!contentReady" :class="wrapClassName">
            <SimpleBlockPreloader v-for="(item, index) in preloaderItems"
                v-bind:key="index"
                v-bind:aspect="aspect"
            ></SimpleBlockPreloader>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
import SimpleBlockItem from './SimpleBlockItem'
import SimpleBlockPreloader from './SimpleBlockPreloader'

export default {
    components: {
        SimpleBlockItem,
        SimpleBlockPreloader
    },      
    data(){
        return{
            blockItems: [],
            blockItemsLocked: [],
            wrapClassName: 'd-flex flex-column',
            preloaderItems: []
        }
    },
    props: ['limit', 'goalName', 'dataFeed'],
    computed: {
        ...mapGetters(['REFRESHING']),
        contentReady: function(){
            return (this.blockItems.length) ? true : false
        },
        aspect: function(){
            return (this.dataFeed === 'site') ? '16:9' : '1:1'
        },
        infinity: function(){
            return (!this.limit) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        }

    },
    methods: {
        itemsIncrease: function(isVisible){
            if (isVisible && this.blockItems.length){
                const shuffled = utils.shuffleArray(this.blockItemsLocked)
                this.blockItems = this.blockItems.concat(shuffled)
            }
        },
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            const limit = (this.limit) ? this.limit : arr.length
            //лочим в неизменном виде
            this.blockItemsLocked = arr
            this.blockItems = shuffled.slice(0, limit)

        },
        contentGetted: function(payload){
            this.arrayClone(payload)
        },
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        }
    },
    created: function(){
        const max = (this.limit) ? this.limit : 48
        for (let i=0; i<max; i++){
            this.preloaderItems.push('')
        }
    },
    watch: {
        REFRESHING: function(){
            if (this.REFRESHING){
                this.blockItems = []
            }
        }
    }
}
</script>
