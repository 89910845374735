export default {
    state: {
        userComeback: false,
    },
    actions: {
        USER_COMEBACK_DETECT(context){
            context.commit('USER_COMEBACK_UPDATE')
        }
    },
    mutations: {
        USER_COMEBACK_UPDATE(state){
            state.userComeback = true
        }
    },
    getters: {
        USER_COMEBACK(state){
            return state.userComeback
        }
    }
}