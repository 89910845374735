<template>
    <div></div>
</template>

<script>
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"
import { collection, addDoc } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth"

export default{
    data(){
        return {
            // firebaseApp: undefined
        }
    },
    //надо ли сюда втыкать асинк - пока не ясно
    created: async function(){
        //web app's Firebase configuration
        const firebaseConfig = {
            apiKey: "AIzaSyDRCc43vsmfnqJVABx8zRZVC5_Vp_E6HmA",
            authDomain: "newspress-b48c5.firebaseapp.com",
            projectId: "newspress-b48c5",
            storageBucket: "newspress-b48c5.firebasestorage.app",
            messagingSenderId: "607133820475",
            appId: "1:607133820475:web:80ab60c940ec5af56a1e1e"
        }
        // Initialize Firebase
        const app = initializeApp(firebaseConfig)
        /*
        const auth = getAuth(app)
        console.log('auth', auth)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;
                console.log('uid', uid)
                // ...
            } else {
                // User is signed out
                // ...
                console.log('00000000000')
            }
        });
        */



        const messaging = getMessaging(app)
        const analytics = getAnalytics(app)
        const db = getFirestore(app)
        console.log(db)
        getToken(messaging, {vapidKey: "BGDBm44OnbP2bulOrUtnl8yeA_RZu7uE0EHOYUsW1pGvaiEHQetzvCuJB32yKLGr6Pzvibgma6fZi9hT3q92X94"}).then(
            (currentToken) => {
                if (currentToken){
                    console.log('во такой токен есть теперь currentToken', currentToken)
                    //тут его отправляем куда-то себе
                }else{
                    console.log('нет токена', currentToken)
                }
            }
        )
        /*
        try {
            const docRef = await addDoc(collection(db, "users"), {
                test: "test123",
                // last: "Lovelace",
                // born: 1815
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
        */

        
        /*
            Получили токен cOEVHbV2xc8QTgmBGiVi00:APA91bH4Grxu3A5goclqVao4sosNb3F1c8j8rSWWOTa4aZlcPk9ySRgMahq9OlOumsjaKHv9ikFP97u7qNGQhNcjC26IEpG03JwYgTxsCpWorIrFXAEHFrg
        */
    },
    watch: {
        firebaseApp: function(){
            console.log('firebase inited')
            Notification.requestPermission()
        }
    }
}

//f5C_zYZYA1eJ7de12ac1kN:APA91bHNtcG8SE8SUrJTWeuMGw4QVjJJMwWNnHnkZMJXjo1KJzYi42YU2wgChSclthQCpQWSgXkq-wPMQEtYfut05L8XhSBh9VLz6rwVMIeLThI-6Bpt3N4
</script>