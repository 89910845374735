<template>
    <div class="text-black">
        <p>
            {{ item }}
        </p>
        <b-alert v-if="!lastItem" show variant="info"> 
            <TextBlock 
                v-bind:dataFeed="dataFeed" 
                v-bind:goalName="'inTextSingle'" 
                v-bind:limit="1"
                v-bind:fontWeight="'bold'"
            ></TextBlock>
        </b-alert>
    </div>
</template>

<script>
import TextBlock from '../integrations/TextBlock.vue'
import { BAlert } from 'bootstrap-vue'
export default {
    components: {
        TextBlock,
        BAlert
    },
    props: ['item', 'index', 'itemsTotal', 'dataFeed'],
    computed: {
        lastItem: function(){
            return ((this.index + 1) === this.itemsTotal)
        }
    }
}
</script>