<template>
    <div>
        <b-navbar variant="dark" :fixed="fixed" class="d-flex flex-column flex-sm-row justify-content-between px-sm-2 px-md-3">

            <b-navbar-brand class="app-nav-brand text-uppercase text-decoration-none" :to="linkTo" :href="linkHref" @click="toHomeClick">
                    <b-icon icon="globe2" class="mr-2 text-light"></b-icon>
                    <span class="text-light">
                        Главные&nbsp;
                    </span>
                    <span class="text-info">
                        Новости
                    </span>
            </b-navbar-brand>

            <!-- Кнопка Обновить на ротаторе -->
            <b-button 
                v-if="isRotator"
                variant="primary" 
                size="sm" 
                :disabled="this.REFRESHING" 
                @click="newsUpdate" 
                :class="buttonClass"
            >
                <span class="mr-2">Обновить</span>
                <b-icon icon="arrow-clockwise" style="transform: rotate(45deg)"></b-icon>                
            </b-button>

            <!-- Кнопка "Следующая новость" на посте -->
            <b-button 
                v-if="isPost"
                variant="primary" 
                size="sm" 
                :disabled="nextButtonDisable" 
                @click="nextNews" 
                :class="buttonClass"
            >
                <span class="font-weight-bold mr-2">Следующая новость</span>
                <b-icon icon="chevron-double-right"></b-icon>                
            </b-button>

        </b-navbar>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters, mapActions } from 'vuex'
import { BNavbar, BNavbarBrand, BButton, BAlert } from 'bootstrap-vue'
export default {
    components: {
        BNavbar,
        BNavbarBrand, 
        BButton,
    },
    data(){
        return {
            posts: [],
            buttonClass: 'd-flex justify-content-center align-items-center my-2',
            nextPostIndex: -1
        }
    },
    computed: {
        // ...mapGetters(['FLOW_READY', 'SUBSCRIBE', 'REFRESHING', 'SCREEN_SIZE']),
        ...mapGetters(['SCREEN_SIZE', 'PLATFORM', 'REFRESHING', 'POSTS', 'NEXT_POST_LIST']),
        emptyHeight: function(){
            return (this.SCREEN_SIZE > 1) ? 'height:58px;' : 'height:105px'
        },
        linkTo: function(){
            //return (this.$route.name === 'post') ? '/' : null
            return (this.isPost) ? '/' : null
        },
        linkHref: function(){
            const hrefHome = '/'
            const hrefRotator = `/${this.$route.name}`
            let result = null
            if (this.isHome){
                result = hrefHome
            }else if (this.isRotator){
                result = hrefRotator
            }   
            return result
        },
        fixed: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) || this.SCREEN_SIZE === 1 || this.PLATFORM === 'desktop') ? 'top' : null
        },
        isRotator: function(){
            const vueContext = this
            return utils.isRotator(vueContext)
        },
        isPost: function(){
            const vueContext = this
            return utils.isPost(vueContext)
        },
        isHome: function(){
            const vueContext = this
            return utils.isHome(vueContext)
        },
        nextButtonDisable: function(){
            return (!this.posts.length || this.REFRESHING)
        },
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX', 'SMI2_FETCH', 'RESET_ALL', 'RESET_POST', 'SET_POST', 
        'TEXT_STATE_SET', 'FAKE_RESET', 'FEED_COUNT_RESET', 'FAKE_POSTS_RESET']),
        newsUpdate: function(){
            const vueContext = this
            utils.safeGoal('ButtonClick', vueContext)
            this.FAKE_RESET()
            /*
            this.RESET_ALL()
            this.FEED_COUNT_RESET()
            this.FETCH_SMI24(vueContext)
            this.SMI2_FETCH(vueContext)
            */
        }, 
        nextNews: function(){
            const vueContext = this
            utils.safeGoal('NextPost', vueContext)
            this.TEXT_STATE_SET(false)
            this.getNext()
            this.FAKE_RESET()
            this.FAKE_POSTS_RESET()
            utils.setFullscreen()
        },
        getNext: function(){
            const posts = this.posts
            let index = (posts.length != this.nextPostIndex) ? this.nextPostIndex + 1 : 0
            const currentId = utils.getIdFromAddressBar()
            let nextId = posts[index].id
            if (nextId === currentId){
                index = (index != posts.length) ? index + 1 : 0
                nextId = posts[index].id
            }
            this.nextPostIndex = index
            this.RESET_POST()
            try {
                this.$router.push(`/post/?p=${nextId}`)
            } catch (e) {
                utils.consoleLog("Ошибка при пушинге в роутер", e)
            }
            const newPost = {
                title: posts[index].title,
                img: posts[index].imgFull,
                text: posts[index].text,
                id: posts[index].id,
            }
            const vueContext = this
            const timeInterval = utils.randomInt(0, 500)
            window.setTimeout(function(){
                vueContext.SET_POST(newPost)
            }, timeInterval)
        },
        toHomeClick: function(){
            if (!this.isRotator){
                utils.setFullscreen()
                const vueContext = this
                utils.safeGoal('toHome', vueContext)
            }

        }
    },
    watch: {
        NEXT_POST_LIST: function(){
            this.posts = this.NEXT_POST_LIST
        }
    },
}
</script>


<style lang="sass" scoped>
button
    span
        font-weight: 500
nav
    z-index: 2   
a         
    transition: all 0.2s
</style>