<template>
    <div id="app-modal" :class="wrapClass" @click="modalClose" :style="modalStyle">
        <div id="app-modal-window" class="app-modal__window">
            <div class="app-modal__header">
                <span id="ModalClose" class="app-modal__title">Информация</span>
                <span id="ModalCross" class="app-modal__cross">+</span>
            </div>
            <div class="app-modal__body">
                <div class="app-modal__icon">
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path fill-rule='evenodd' clip-rule='evenodd' d='M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm9.727 3.535C8.994 12.528 8.047 13 7.512 13c-.87 0-1.206-.534-1.206-1.1 0-.244.076-.58.122-.778l.967-4.024H5.927a.212.212 0 0 1-.206-.265l.073-.283a.297.297 0 0 1 .234-.22l2.842-.52a.423.423 0 0 1 .488.52l-1.232 5.112a.18.18 0 0 0 .102.207.205.205 0 0 0 .237-.051l1.544-1.448a.322.322 0 0 1 .428-.05.282.282 0 0 1 .063.4l-.773 1.035zm.229-7.36c-.123.535-.58.887-1.176.887-.626 0-.962-.397-.962-.825a1.6 1.6 0 0 1 .045-.351C7.986 3.35 8.443 3 9.04 3c.626 0 .962.397.962.825 0 .091-.015.229-.045.35z' fill='#7c7c7c'/>
                    </svg>        
                </div>
                <div class="app-modal__text">Мы заботимся о безопасности ваших данных. На сайте используется <b id="ModalLink1">cookies</b> - это абсолютно безопасно. Продолжая использовать сайт, вы принимаете <b id="ModalLink2">условия</b>.</div>
            </div>
            <div class="app-modal__footer">
                <b-button class="app-modal__button" variant="secondary">Ок</b-button>
                <b-button class="app-modal__button" variant="dark">Закрыть</b-button>                    
            </div>        
        </div>
    </div>
</template>

<script>
import { BButton} from 'bootstrap-vue'
import utils from '../../functions/functions'

export default {
    components: {
        BButton
    },    
    data(){
      return {
        wasShowed: false,
        delayFinished: true
      }
    },
    props: ['modalReady', 'delayTime', 'targetEvent'],
    methods: {
        modalClose: function(){
            this.wasShowed = true
            const vueContext = this
            utils.safeGoal('modalClose', vueContext)
            if (this.targetEvent === 'fullscreen'){
                console.log('Ставим фуллскрин по клику на модалку')
                utils.setFullscreen()
            } 
        }
    },
    computed: {
        modalStyle: function(){
            return (this.modalReady && this.delayFinished && !this.wasShowed)  ? '' : 'display:none;'
        },
        wrapClass: function(){
            const classBase = 'app-modal app-install-trigger'
            const classTrigger = (this.targetEvent === 'pwa') ? 'app-install-trigger' : ''
            return `${classBase} ${classTrigger}`
        }
    },
    beforeMount: function(){
        if (this.delayTime != 0){
            //скидываем delayFinished в false
            this.delayFinished = false
            const vueContext = this
            //возвращаем обратно в true через интервал delayTime
            window.setTimeout(function(){
                vueContext.delayFinished = true
            }, vueContext.delayTime)
        }
    },
    watch: {
        modalStyle: function(){
            if (this.modalStyle === ''){
                const vueContext = this
                utils.safeGoal('ModalShow', vueContext)
            }
        }
    }

}
</script>

<style>
    @keyframes background-fade {
        0%{
            background-color: rgba(0, 0, 0, 0);
        }
        100%{
            background-color: rgba(0, 0, 0, 0.8);
        }        
    }
    @keyframes window-scale {
        0%{
            transform: scale(0);
        }
        100%{
            transform: scale(1);
        }
    }
    /*
    .app-modal-overlay{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;      
      z-index: 1031;      
    }
    */
    .app-modal{
        animation-name: background-fade;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 32px;
        padding-right: 32px;
        z-index: 1101;
        font-size: 1rem;
    }
    .app-modal__window{
        transform: scale(0);
        animation-name: window-scale;
        animation-duration: 0.27s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        min-width: 320px;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid gray;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 0.3rem;
    }
    .app-modal__header{
        padding: 14px;
        display: flex;
        justify-content: space-between;
        background-color: rgb(108 117 125 / 16%);
    }
    .app-modal__title{
      opacity: 0.8;
    }
    .app-modal__text b{
        cursor: pointer
    }

    .app-modal__cross{
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: white;
      transform: rotate(45deg);
      font-size: 64px;
      color: #6c757d;
      color: #343a40;
      cursor: pointer;    
    }
    .app-modal__body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
    }
    .app-modal__icon{
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        margin-right: 16px;
    }
    .app-modal__icon svg{
        width: 100%;
        height: auto;
    }
    .app-modal__text{
        padding: 8px;
        font-size: 18px;
        margin-top: 16px;
        margin-bottom: 32px;
        font-style: italic;
        opacity: 0.6;
    }
    .app-modal__text b{
      text-decoration: underline;
    }
    .app-modal__input{
      display: none;
      padding: 16 14px 32 14px;
      justify-content: center;
      background: rgb(108 117 125 / 16%);
    }
    .app-modal__input input{
      opacity: 1;
      transform: scale(2);
    }
    .app-modal__input label{
      margin-left: 16px;
    }
    .app-modal__footer{
        display: flex;
        justify-content: space-between;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 14px;
    }
    .app-modal__button{
        overflow: hidden;
        padding: 8px 16px 8px 16px;
        border: 1px solid;
        width: calc(50% - 8px);
        text-align: center;
        border-radius: 0.25rem;
        color: white;
    }
    @keyframes solution-button-fade {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
</style>