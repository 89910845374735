<template>
    <div>
        <!-- Тройной красный -->
        <b-alert show variant="danger">
            <TextBlock 
                v-bind:dataFeed="dataFeed" 
                v-bind:goalName="'TextBlock'" 
                v-bind:limit="3"
                v-bind:fontWeight="'bold'"
                v-bind:borderColor="'secondary'"
                v-bind:icon="'exclamation-octagon-fill'"
            ></TextBlock>            
        </b-alert>
        <!-- Абзацы с внутритекстом -->
        <TextPartItem v-for="(item, index) in items"
            v-bind:key="index"
            v-bind:index="index"
            v-bind:item="item"
            v-bind:itemsTotal="itemsTotal"
            v-bind:dataFeed="dataFeed"
        >
        </TextPartItem>
        <!-- Кружочки -->
        <BlockRounded
            v-bind:goalName="'BlockRounded'"
            v-bind:limit="2"
            v-bind:dataFeed="dataFeed" 
        ></BlockRounded>
    </div>
</template>



<script>
import { BAlert } from 'bootstrap-vue';
import TextBlock from '../integrations/TextBlock.vue'
import TextPartItem from './TextPartItem.vue'
import BlockRounded from '../integrations/BlockRounded.vue'
export default{
    components: {
        TextPartItem,
        TextBlock,
        BAlert,
        BlockRounded 
    },
    props: ['items', 'dataFeed'],
    computed: {
        itemsTotal: function(){
            return this.items.length
        }
    }
}
</script>